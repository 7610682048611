import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import queryString from "query-string"
import { graphql, useStaticQuery } from "gatsby"
import Seo from "../../components/seo/seo"
import LayoutDiagnostic from "../../components/layoutDiagnostic/layoutDiagnostic"
import BlockSteps from "../../components/blockSteps/BlockSteps"
import SliderCustom from "../../components/sliderCustom/sliderCustom"
import moment from "moment"
import "./index.scss"
import utils from "../../utils/utils"
import { getTaxoLien, getTaxoMaterials } from "../../hooks/hooks"
import tracking from "../../utils/tracking"
import ImgCustom from "../../components/imgCustom/imgCustom"
import { LocalStorage } from "ttl-localstorage"
import diagnostics from "../../utils/diagnostics"
import links from "../../utils/links"

export const query = graphql`
  query {
    nodeRappel {
      field_rappel_intro {
        value
      }
      field_rappel_outro {
        value
      }
    }
    allMaterialsJson {
      nodes {
        name
        data {
          label
          icon
          price
          name
        }
      }
    }
    allImageSharp {
      nodes {
        fluid {
          originalImg
          originalName
        }
      }
    }
    allTaxonomyTermFraisInstallation {
      nodes {
        field_frais
      }
    }
  }
`

const blockSteps = [
  {
    label: "Un conseiller vous appelle",
    icon: "4",
    detail: "dans les 24H pour ajuster votre offre.",
  },
  {
    label: "Un technicien se rend chez vous",
    icon: "5",
    detail:
      "pour installer le système d'alarme et vous présenter son fonctionnement.",
  },
  {
    label: "Votre logement est sécurisé",
    icon: "3",
    detail: "Vous pouvez profiter de votre domicile en toute sérénité",
    addSeparator: true,
  },
]
const intervvalTokenTime = 1
const addTarif = 19.9
const formuleId = "MPM_Initiale"

const constructLiOptional = (el, index) => {
  return (
    <li key={index} className="li item py-3">
      <div className="d-f ai-c fd-lg-c card-item">
        <div className="img-container mr-1 d-f jc-c ai-c mr-1 mr-md-0">
          <ImgCustom src={el.icon.path} alt={el.icon.alt} />
        </div>
        <div>
          <p className="fs-14 lh-24 mb-0 ff-gb cg-7">{el.label}</p>
          <p className="cg-3 fs-14 lh-24 mb-0 ta-lg-c">
            {el.priceOptional}€/mois
          </p>
        </div>
      </div>
    </li>
  )
}

const checkLocalStorage = () => {
  const getLocal = window.localStorage.getItem("diagnostic_total")
  if (getLocal) {
    const checkLocal = JSON.parse(getLocal)
    if (typeof checkLocal === "object") {
      const { time } = checkLocal
      /** CHECK TOKENTIME */
      const momentLocal = moment(time)
      if (momentLocal.isValid()) {
        const diff = momentLocal.diff(moment(), "days")
        if (intervvalTokenTime > diff) {
          return { ...checkLocal, isValid: true }
        }
      }
    }
  }
  return { isValid: false }
}
const seoTitle = "Résultat de la simulation"

const index = ({ data = null }) => {
  
  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])

  const [isFraisLoaded, setIsFraisLoaded] = useState(false)
  const [fraisInstallation, setFraisInstallation] = useState()

  useEffect(() => {
    setFraisInstallation(data.allTaxonomyTermFraisInstallation.nodes[0].field_frais)
    setIsFraisLoaded(true)
  })
  
  const dataCall = data
    ? {
        intro: data.nodeRappel.field_rappel_intro.value,
        outro: data.nodeRappel.field_rappel_outro.value,
      }
    : null
  const materialsJson = getTaxoMaterials()

  const iconMaterial = data ? data.allImageSharp.nodes : null
  const [materials, setMaterials] = useState([])
  const [additionals, setAdditionals] = useState([])
  const [optional, setOptional] = useState([])
  const [toggleOptinal, setToggleOptional] = useState(false)
  const [localData, setLocalData] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const numPhone = getTaxoLien("numero telephone")
  useEffect(() => {
    const checkLocal = checkLocalStorage()
    /** Tracking */
    tracking.track("typeTemplate", "diagnostic")
    tracking.track("page_name", seoTitle + (isTherecampagne ? "/" + campagne : ""))
    tracking.track("categories", "telesurveillance")
    //tracking.trackinit()

    setTimeout(() => {
      if ("tC" in window) {
        if (tC.hasOwnProperty("event")) {
          if (tC.event.hasOwnProperty("pianoEvent")) {
            tracking.trackDiagInfo(
              "page.display",
              "telesurveillance",
              [],
              "diagnostic_contact"
            )
          }
        }
      }
    }, 1000)

    if (checkLocal.isValid && materialsJson && iconMaterial) {
      setMaterials(
        materialsJson.filter(e => e.includeOnFormule.includes(formuleId))
      )
      setOptional(materialsJson.filter(e => e.isOptional))
      const getAdditionals = materialsJson.filter(el => el.nameOptional)
      if (getAdditionals) {
        let formatAdditional = []
        getAdditionals.forEach(element => {
          const elementCount = checkLocal.hasOwnProperty(element.nameOptional)
            ? checkLocal[element.nameOptional]
            : 0
          formatAdditional.push({
            ...element,
            count: elementCount,
            total: elementCount * parseFloat(element.priceOptional),
          })
        })
        setAdditionals(formatAdditional)
      }
      if (materialsJson.filter(e => e.isOptional).length < 3)
        setToggleOptional(true)

      checkLocal.total = (checkLocal.total + addTarif).toFixed(2)
      setLocalData(checkLocal)
      /** UTILS */
      window.addEventListener("resize", () => {
        setIsMobile(utils.isTabletteSize())
      })
      setIsMobile(utils.isTabletteSize())
      /** track */
      tracking.trackDiagInfo("click.navigation", "/diagnostic/acceptation" + (isTherecampagne ? "/" + campagne : ""))
    } else {
      localStorage.removeItem("diagnostic")
      window.location.href = "/diagnostic" + (isTherecampagne ? "/" + campagne : "")
    }
    /** REMOVE LOCALSTORAGE */
    //utils.removeLocalDiag()
  }, [])

  return (
    <LayoutDiagnostic
      dataCall={dataCall}
      iconRound={false}
      isViewPromoOnHeader={true}
      isHeaderSticky={true}
    >
      <Seo title={seoTitle} linkCanonical={"acceptation/"} />
      {localData && isFraisLoaded && (
        <div className="acceptation-container d-f fd-c ai-c jc-c w-100 py-4 px-2">
          <div className="d-f ai-c fd-lg-c br-12 bc-2 px-3 py-2">
            <span
              style={{ height: "33px", width: "33px" }}
              className="icon-operator mr-2"
            ></span>
            <div>
              <p className="fs-24 lh-32 ff-gb cg-7 mb-0 fs-ms-18 ta-lg-c lg-2 px-2">
                Merci, un conseiller vous recontactera dans les 24h.
              </p>
              <p className="mb-0 fs-16 lh-24 ff-gm cg-7 d-lg-f fd-lg-c jc-lg-c ai-lg-c px-2">
                ou contactez-le directement :{" "}
                <span className="fs-16 lh-24 ff-gb mt-lg-1">{numPhone}</span>{" "}
                (appel non surtaxé)
              </p>
            </div>
          </div>
          <div className="wrapper no-padding">
            <div className="container-offer d-f ai-s py-4 fd-lg-c">
              {/** PRICE DETAILS */}
              <div className="content-total d-f fd-c ai-c br-22 py-5 px-7 px-lg-2 jc-fs box-shadow-s bc-w br-lg-0 mb-4">
                <span
                  style={{ width: "100px", height: "100px" }}
                  className="icon-illus-integrale d-b mb-2 d-lg-n"
                ></span>

                <h3 className="fs-24 lh-32 ff-gb cg-7 mb-2 px-4 ta-c">
                  Votre offre personnalisée
                </h3>
                <p className="c-1 fs-20 ff-gm mb-1">
                  <span className="fs-40 ff-gb lh-56">{localData.total}€</span>
                  /mois
                </p>
                <p className="ta-c ff-gbook fs-14 lh-4 mb-3 px-4">
                  Pour votre sécurité ou votre confort, cette estimation pourra
                  être ajustée avec l'un de nos conseillers.{" "}
                </p>
                <div className="br-12 bx-2 p-3 br-12 bcg-2">
                  <p className="c-1 mb-2 fs-18 lh-32 ff-gb">Formule Initiale</p>
                  <div className="d-f ai-c mb-1 ai-lg-fs">
                    <span
                      style={{ height: "24px", width: "40px" }}
                      className="icon-mobile-empty mr-1"
                    ></span>
                    <p className="mb-0 ff-gbook fs-16 lh-24 cg-7">
                      <span className="ff-gb">
                        Gérez vous-même vos déclenchements. Si vous n'êtes pas
                        disponible le centre de télésurveillance prend le relai.
                      </span>
                    </p>
                  </div>
                  <p className="ff-gbook fs-14 lh-24 mb-0">
                    Une notification vous est transmise à chaque déclenchement.
                    Grâce à l'extrait vidéo, vérifiez la nature de l'alerte et
                    transférez-la au centre de télésurveillance en cas de doute
                    (2 transferts gratuits /mois).
                  </p>
                  <div className="py-3">
                    <ul className="ul mb-2">
                      <li className="li d-f fd-r ai-c py-1">
                        <span
                          style={{
                            height: "20px",
                            minWidth: "20px",
                            width: "20px",
                            borderRadius: "50%",
                          }}
                          className="icon-check-white mr-1 bc-1 d-f ai-c jc-c"
                        ></span>
                        <p className="fs-16 lh-24 ff-gbook cg-7 mb-0">
                          Télésurveillance 24h/24 et 7j/7{" "}
                        </p>
                      </li>
                      <li className="li d-f fd-r ai-c py-1">
                        <span
                          style={{
                            height: "20px",
                            minWidth: "20px",
                            width: "20px",
                            borderRadius: "50%",
                          }}
                          className="icon-check-white mr-1 bc-1 d-f ai-c jc-c"
                        ></span>
                        <p className="fs-16 lh-24 ff-gbook cg-7 mb-0">
                          Matériel discret et ergonomique
                        </p>
                      </li>
                      <li className="li d-f fd-r ai-c py-1">
                        <span
                          style={{
                            height: "20px",
                            minWidth: "20px",
                            width: "20px",
                            borderRadius: "50%",
                          }}
                          className="icon-check-white mr-1 bc-1 d-f ai-c jc-c"
                        ></span>
                        <p className="fs-16 lh-24 ff-gbook cg-7 mb-0">
                          Application mobile avec vidéo live HD
                        </p>
                      </li>
                    </ul>
                  </div>
                  <p className="d-f ai-c ff-gbook cg-7 fs-16 lh-24 mb-0">
                    <span
                      style={{ height: "24px", width: "24px" }}
                      className="icon-works mr-1"
                    ></span>
                    Frais d'installation : {fraisInstallation}€
                  </p>
                </div>
              </div>
              {/** LIST MATERIALS */}
              <div className="content-detail p-r h-100 d-f fd-c pl-2 pl-lg-0">
                <div className="br-22 p-r  box-shadow-s p-5 p-lg-3 bc-w mb-1 br-lg-0">
                  <h3 className="ff-gb fs-18 mb-2 cg-7">Contenu du pack</h3>
                  <ul className="ul d-f materials">
                    {materials && (
                      <>
                        {materials.map((el, index) => (
                          <li key={index} className="li item py-2 ">
                            <div className="d-f ai-c">
                              <div className="img mr-lg-1">
                                <ImgCustom
                                  src={el.icon.path}
                                  alt={el.icon.alt}
                                />
                              </div>
                              <p className="fs-14 mb-0 ff-gm">
                                {el.labelResultat}
                              </p>
                            </div>
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                  {additionals.filter(e => e.count).length > 0 && (
                    <span
                      className="icon-add br-50 bcg-2 p-a"
                      style={{ height: "48px", width: "48px" }}
                    ></span>
                  )}
                </div>
                {additionals.filter(e => e.count).length > 0 && (
                  <div className="br-22 box-shadow-s p-5 p-lg-3 bc-w f-g br-lg-0">
                    <h3 className="ff-gb fs-18 mb-2 cg-7">
                      Matériel complémentaire
                    </h3>
                    <ul className="d-f ul additional d-f fd-c">
                      {additionals && (
                        <>
                          {additionals
                            .filter(e => e.count)
                            .map((el, index) => (
                              <li key={index} className="li">
                                <div className="item d-f ai-c py-2">
                                  <div className="p-r icon-content">
                                    <span
                                      style={{
                                        minHeight: "36px",
                                        minWidth: "36px",
                                        borderRadius: "50%",
                                      }}
                                      className="index-count p-a d-f ai-c jc-c  bc-1 c-w fs-14 lh-24"
                                    >
                                      +{el.count}
                                    </span>
                                    <ImgCustom
                                      src={el.icon.path}
                                      alt={el.icon.alt}
                                    />
                                  </div>
                                  <div>
                                    <p className="fs-16 lh-24">{el.label}</p>
                                    <p className="cg-3 fs-14 ff-gbook">
                                      <span className="ff-fb  fs-16">
                                        {el.total}€
                                      </span>
                                      /mois ({el.priceOptional}€/pièce)
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="wrapper">
            <BlockSteps
              data={blockSteps}
              line="icon"
              sizeItem={"l fd-md-r ai-lg-fs"}
              title={"Les prochaines étapes"}
              clasLabel={"ff-gb mt-3"}
              classTitle="fs-28 mb-4"
              classBlock="bc-t px-md-2"
            />
          </div>
          <div className="wrapper no-padding">
            <div className="container-all-material d-f fd-c ai-c w-100 py-5 br-22 br-lg-0 bc-w box-shadow-s">
              <h3 className="cg-7 fs-28 ff-gb ta-c">En option</h3>
              <p className="fs-14 ff-gbook lh-24 ta-c px-4">
                Vous souhaitez <span className="fw-b">bénéficier</span> d'autres
                produits ? Voici les options qui s’offrent à vous.
              </p>
              <ul
                className={
                  toggleOptinal ? "ul content is-active" : "ul content"
                }
              >
                {!isMobile ? (
                  <>
                    {optional &&
                      optional
                        .filter((e, index) => index > 3)
                        .map((el, index) => constructLiOptional(el, index))}

                    <ul className="hide ul w-100 d-f f-w">
                      {optional &&
                        optional
                          .filter((e, index) => index < 3)
                          .map((el, index) => constructLiOptional(el, index))}
                    </ul>
                  </>
                ) : (
                  <SliderCustom>
                    {optional &&
                      optional.map((el, index) =>
                        constructLiOptional(el, index)
                      )}
                  </SliderCustom>
                )}
              </ul>
              {optional.length > 3 && (
                <button
                  onClick={() => setToggleOptional(!toggleOptinal)}
                  className={
                    toggleOptinal
                      ? "btn-see-more c-1 d-lg-n ff-gb f-w-n d-f fd-a ai-c is-active"
                      : "btn-see-more c-1 d-lg-n ff-gb f-w-n d-f fd-a ai-c"
                  }
                >
                  {!toggleOptinal ? "Afficher" : "Cacher"} l’ensemble du
                  matériel <span className="icon-arrow-down-c1 ml-2"></span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </LayoutDiagnostic>
  )
}

export default index
